import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import styled from '@emotion/styled'
import useSiteSettings from '~utils/useSiteSettings'
import DataViewer from '~utils/DataViewer'
import SanityLink from '~components/SanityLink'
import { Logo, Cart } from '~components/Svg'
import Section from '~components/Section'
import { mobile } from '~styles/global'
import MobileMenu from '~components/MobileMenu'
import useBreakpoint from '~utils/useBreakpoint'
import { useCart } from '~context/siteContext'
import Headroom from 'react-headroom'
import { css, Global } from '@emotion/react'

// create custom tablet breakpoint just for this component
const tablet = '@media (max-width: 1200px)'

const Header = ({ className, location }) => {
	const { navigation } = useSiteSettings()
	const half = Math.ceil(navigation.length / 2)
	const leftNav = navigation.slice(0, half)
	const rightNav = navigation.slice(half)
	const [top, setTop] = useState()
	const [pinned, setPinned] = useState()
	const [menuOpen, setMenuOpen] = useState(false)
	const { isMobile } = useBreakpoint()
	const { openCart, cartCount } = useCart()

	useEffect(() => {
		if(window.pageYOffset >= 100){
			setTop(false)
		} else if (window.pageYOffset < 100){
			setTop(true)
		}
		window.addEventListener('scroll', () => {
			if (window.pageYOffset < 100){
				setTop(true)
			} else {
				setTop(false)
			}
		})
		return () => window.removeEventListener('scroll', () => {
			if (window.pageYOffset < 100){
				setTop(true)
			} else {
				setTop(false)
			}
		})
	}, [])

	useEffect(() => {
		setMenuOpen(false)
	}, [isMobile, location])

	return(
		<>
			{menuOpen &&
				<Global styles={css`
					body {
						overflow: hidden;
					}
				`}/>
			}
			<StyledHeadroom 
				onPin={() => setPinned(true)}
				onUnpin={() => setPinned(false)}
			/>
			<Wrap className={className} show={top || pinned}>
				<Gradient show={pinned && !top}/>
				<DataViewer data={navigation} name="navigation"/>
				<Nav>
					<Left clickable={top || pinned}>
						<DesktopNav>
							{leftNav.map(item => 
								<NavLink 
									className='h6' 
									key={item.text} 
									link={item.link} 
									activeClassName='active'
								>
									{item.text}
								</NavLink>
							)}
						</DesktopNav>
						<MenuToggle 
							className='h6'
							onClick={() => setMenuOpen(!menuOpen)}
						>
							<div>
								<Hamburger>
									<Line open={menuOpen}/>
									<Line />
									<Line open={menuOpen}/>
								</Hamburger>
							</div>
							menu
						</MenuToggle>
					</Left>
					<Right clickable={top || pinned}>
						<DesktopNav>
							{rightNav.map(item => 
								<NavLink 
									className='h6' 
									key={item.text} 
									link={item.link}
									activeClassName='active'
								>
									{item.text}
								</NavLink>)}
						</DesktopNav>
						<CartButton open={menuOpen} onClick={() => openCart()}>
							<Relative>
								<CartCounter className='xs'>
									{cartCount()}
								</CartCounter>
								<CartIcon />
							</Relative>
						</CartButton>
					</Right>
				</Nav>
				<LogoLayer open={menuOpen}>
					<LogoContainer>
						<LogoLink to={'/'} top={top} pinned={pinned}/>
						<StyledLogo top={top}/>
					</LogoContainer>
				</LogoLayer>
			</Wrap>
			<MobileMenu open={menuOpen} menu={navigation}/>
		</>
	)
}


const StyledHeadroom = styled(Headroom)`

`
const Wrap = styled.header`
	position: fixed;
  top: 0;
  right: 0;
	left: 0;
  z-index: 100;
	opacity: ${ props => props.show ? '1' : '0'};
	transition: opacity 0.3s;
	pointer-events: ${ props => props.show ? 'all' : 'none'};
`
const Gradient = styled.div`
	opacity: ${ props => props.show ? '1' : '0'};
	transition: opacity 0.5s;
	position: fixed;
  top: 0;
  right: 0;
	left: 0;
  z-index: -1;	
	pointer-events: none;
	height: 300px;
	background: linear-gradient(180deg, #010B21 0%, #010B21 25%, rgba(1, 11, 33, 0) 93.97%);
	${mobile}{
		height: 200px;
	}
`
const Nav = styled.nav`
	display: flex;
	justify-content: space-between;
	grid-column: span 12;
	height: 112px;
	align-items: center;
	pointer-events: none;
	${tablet}{
		height: 62px;
	}
`
const Left = styled.div`
	display: flex;
	margin-left: 54px;
	pointer-events: ${ props => props.clickable ? 'all' : 'none'};
	${tablet}{
		margin-left: 19px;
		align-items: center;
		button {
			display: flex;
			align-items: center;
		}
	}
`
const DesktopNav = styled.div`
	${tablet}{
		display: none;
	}
`
const MenuToggle = styled.button`
	display: none;
	${tablet}{
		display: flex;
	}
`
const LogoContainer = styled.div`
	width: 175px;
	height: 150px;
	margin-top: var(--s);
	position: relative;
	pointer-events: none;
	${tablet}{
		width: 91px;
    height: 81px;
	}
`
const LogoLink = styled(Link)`
	pointer-events: ${ props => props.pinned ? 'all' : 'none'};
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: ${ props => props.top ? '150px' : '54px'};
	${tablet}{
		height: ${ props => props.top ? '81px' : '30px'};
	}
`
const StyledLogo = styled(Logo)`
	width: 100%;
	.shield{
		opacity: ${ props => props.top ? '1' : '0'};
		transform: ${ props => props.top ? 'translate(0)' : 'translateY(-30px)'};
		transition: opacity 0.25s, transform 0.25s;
	}
`
const Right = styled.div`
	display: flex;
	align-items: center;
	margin-right: 35px;
	pointer-events: ${ props => props.clickable ? 'all' : 'none'};
	${tablet}{
		margin-right: 22px;
	}
`
const NavLink = styled(SanityLink)`
	margin-right: 25px;
	position: relative;
	:after{
		content: '';
		height: 1px;
		position: absolute;
		bottom: -0.5em;
		left: 0;
		right: 0;
		background-color: transparent;
		transition: background-color 0.3s;
	}
	&.active,:hover{
		:after{
			background-color: var(--white);
		}
	}
`
const CartButton = styled.button`
	opacity: ${ props => props.open ? '0' : '1'};
	transition: opacity 0.25s;
	pointer-events: ${ props => props.open ? 'none' : 'all'};
`
const Relative = styled.div`
	position: relative;
`
const CartCounter = styled.div`
	position: absolute;
	top: 8px;
	bottom: 0;
	left: 0;
	right: 0;
	${tablet}{
		font-size: 10px;
	}
`
const CartIcon = styled(Cart)`
	width: 24px;
	${tablet}{
		width: 22px;
	}
`
const LogoLayer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	z-index: -1;
	opacity: ${ props => props.open ? '0' : '1'};
	transition: opacity 0.25s;
`
const Hamburger = styled.div`
	display: grid;
	grid-row-gap: 3px;
	grid-template-rows: repeat(3, 1fr);
	margin-right: 9px;
	margin-top: 0px;
`
const Line = styled.div`
	height: 1px;
	width: 16px;
	background-color: var(--white);
	opacity: ${ props => props.open ? '0' : '1'};
	transition: opacity 0.25s;
`
Header.propTypes = {
	className: PropTypes.string,
	location: PropTypes.object,
}

export default Header
